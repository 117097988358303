@tailwind base;
@tailwind components;
@tailwind utilities;

Body {
    overflow-x: hidden;
}

/* img {
  -webkit-animation: fadeIn 0.5s ease-in-out;
  -moz-animation: fadeIn 0.5s ease-in-out;
  -o-animation: fadeIn 0.5s ease-in-out;
  animation: fadeIn 0.5s ease-in-out;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/*  our-dev-services animation Start*/
.our-dev-services {
    -webkit-animation: fadeIn 1.8s ease-in-out;
    -moz-animation: fadeIn 1.8s ease-in-out;
    -o-animation: fadeIn 1.8s ease-in-out;
    animation: fadeIn 1.8s ease-in-out;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    40% {
        opacity: 0.3;
    }

    60% {
        opacity: 0.5;
    }

    80% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    40% {
        opacity: 0.3;
    }

    60% {
        opacity: 0.5;
    }

    80% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
    }
}

/*  our-dev-services animation End*/

/** Button Css Start */
.btn-primary {
    @apply text-[#410EAD] font-manrope text-base shadow-buttonShadow rounded whitespace-nowrap hover:bg-[#29e248] hover:text-black hover:shadow-none font-semibold py-2 px-4 bg-white;
}

.btn-primary-purple {
    @apply text-white font-manrope text-base shadow-buttonShadow rounded whitespace-nowrap hover:bg-[#29e248] hover:text-black hover:shadow-none font-semibold py-2 px-4 bg-[#7B3FE4];
}

.btn-primary-yellow {
    @apply text-[#410EAD] font-manrope text-base shadow-buttonShadow rounded whitespace-nowrap hover:bg-[#410EAD] hover:text-[#FFCF10] hover:shadow-none font-semibold py-2 px-4 bg-[#FFCF10];
}

.btn-border-primary-purple {
    @apply text-[#410EAD] text-base shadow rounded whitespace-nowrap hover:bg-[#29e248] hover:text-black hover:shadow-none font-semibold py-2 px-4 bg-white border border-[#410EAD];
}

/** Button Css End */

/* ==================================== */

.NFTFractures-border {
    border-bottom: 1.5px solid transparent;
    border-image: linear-gradient(to right, #ffffff, #410ead, #ffffff) 1;
}

.NFTFractures-border1 {
    border-right: 1.5px solid transparent;
    border-image: linear-gradient(to bottom, #ffffff, #410ead, #ffffff) 1;
}

.GradientBorder {
    padding-bottom: 12px;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(to left, #ffffff, #410ead) 1;
}

/*?=== FADE IN DOWN START ===*/
.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

/*?=== FADE IN DOWN END ===*/

/* ==================================== */

/* Ribbon Start  */
.ribbon {
    width: min-content;
    white-space: nowrap;
    height: 48px;
    /* margin: 100px auto 0px; */
    position: relative;
    color: #fff;
    align-items: center;
    display: flex;
    /* text-align: center; */
    background: #7b3fe4;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    font-family: var(--font-manrope);
    padding: 12px 20px;
}

.ribbon i {
    position: absolute;
}

.ribbon i:first-child,
.ribbon i:nth-child(1) {
    position: absolute;
    left: -20px;
    bottom: -20px;
    z-index: -1;
    border: 20px solid transparent;
    border-right-color: #8f4fff;
}

/* Ribbon End  */

/* ==================================== */

/* Gradient border */

.gradient-border {
    padding-bottom: 12px;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0.65) 3.6%,
            #410ead 54.42%,
            rgba(255, 255, 255, 0.65) 98.27%
        )
        1;
}

/* Over Gradient border */

/* ==================================== */

/* Contact Form Css Start */
.MuiListItemIcon-root {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 10px !important;
}

.react-tel-input .form-control {
    border: none !important;
    width: 100% !important;
    font-size: 14px !important;
}

.react-tel-input .form-control:focus {
    border-color: none !important;
    box-shadow: none !important;
}

.outline-none {
    outline: none;
}

.upload-file::-webkit-file-upload-button {
    visibility: hidden;
    width: 1px;
}

.upload-file::before {
    content: "Upload file";
    display: inline-block;
    color: black;
    font-weight: 500;
    background-color: #cdb7fa;
    text-decoration-style: double;
    padding: 5px 12px;
    border-radius: 8px;
    cursor: pointer;
}

.upload-cv::-webkit-file-upload-button {
    visibility: hidden;
    width: 1px;
}

.upload-cv::before {
    content: "Upload Your CV / Resume";
    display: inline-block;
    color: white;
    background-color: rgb(126, 126, 126);
    text-decoration-style: double;
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 8px;
    cursor: pointer;
}

.error {
    color: red;
}

input:focus {
    outline: none;
}

ul li {
    list-style: none;
}

.dropdown-left-border {
    margin-right: 16px;
    border: 2px solid #410ead;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* .dropdown-right-border {
  margin-left: 16px;
  border: 2px solid #410ead;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
} */

/* Contact Form Css End */

/* ==================================== */

/* all page opacity */
/* .bg-page-blur:hover { */
/* opacity: 1 !important; */
/* } */

/* main:hover .bg-page-blur { */
/* filter: blur(1px); */
/* opacity: 0.2; */
/* } */

/* over all page opacity */

/* ==================================== */
/* Mitting Style */
.lmtWIHO_gkbTeeyuvoJC.mOUYF5ZmuNL6I7t0mSFg {
    background-color: red !important;
}

/* Mitting Style Over */
/* ==================================== */

/*! ScrollBar Css Start */
#style-7::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d9d9d9;
    /* border-radius: 10px; */
}

#style-7::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #d9d9d9;
}

#style-7::-webkit-scrollbar-thumb {
    /* border-radius: 10px; */
    background: #410ead;
}

/*! ScrollBar Css End */

/* ==================================== */

/* ==================================== */

/*! Navbar ScrollBar Css Start */
.navbarScroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #410ead;
    /* border-radius: 10px; */
}

.navbarScroll::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #d9d9d9;
}

.navbarScroll::-webkit-scrollbar-thumb {
    /* border-radius: 10px; */
    background: #d9d9d9;
}

/*! Navbar ScrollBar Css End */

/* ==================================== */
/* Main Scrollbar Css */
::-webkit-scrollbar-track {
    /* border-radius: 10px; */
    cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #410ead;
    /* border-radius: 10px; */
    cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #5206f7;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Over Main Scrollbar Css */
/* Footer image */
.image {
    /* object-fit: contain; */
    width: 5% !important;
    /* position: relative !important; */
    /* height: unset !important; */
}

.imageHover:hover {
    transform: scale(1.03);
    transition: transform 0.2s;
    transition: 500ms linear;
}

.techBG {
    background: rgba(56, 56, 56, 0.2);
    border-radius: 10px;
}

.techNav:hover {
    background: linear-gradient(
        90deg,
        rgba(189, 151, 255, 0.2) 0%,
        rgba(189, 151, 255, 0) 96.2%
    );
    padding: 8px 6px;
    border-left: 2px solid rgba(191, 154, 255, 1);
}

/* .techNavActive {
  background: linear-gradient(
    90deg,
    rgba(189, 151, 255, 0.2) 0%,
    rgba(189, 151, 255, 0) 96.2%
  );
  padding: 8px 6px;
  border-left: 2px solid rgba(191, 154, 255, 1);
} */
.techNavActive {
    background-color: #6e41d2;
    font-weight: 500;
    padding: 8px 6px;
    border-radius: 7px;
    border-left: 2px solid rgba(191, 154, 255, 1);
}

.imgSlider {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container11 {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #282c34;
}

._cUP1np9gMvFQrcPftuf.xahN8AEzyAvQtVj17TPv {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

._cUP1np9gMvFQrcPftuf {
    display: block !important;
    justify-content: start !important;
}

/* Footer image Over*/
@media screen and (min-width: 1024px) {
    /* Page Background Shadow */
    .bg-shade-left {
        @apply absolute rounded-full w-auto lg:w-96 xl:w-[800px] h-60 md:h-72 lg:h-96 xl:h-[800px] bottom-[600px] md:-bottom-36 lg:-bottom-40 xl:top-0 2xl:-left-10 bg-[#B596DE] filter blur-3xl xl:blur-3xl 2xl:blur-[350px] opacity-20 2xl:opacity-40;
    }

    .bg-shade-right {
        @apply absolute rounded-full w-auto lg:w-96 xl:w-[800px] h-64 xl:h-[800px] lg:h-96 md:h-80 -top-[600px] 2xl:-right-10 bg-[#B596DE] filter blur-3xl xl:blur-3xl 2xl:blur-[350px] opacity-20 2xl:opacity-40 overflow-hidden;
    }

    .bg-shade-center {
        @apply absolute rounded-full w-auto lg:w-96 xl:w-[900px] h-64 xl:h-[900px] lg:h-96 md:h-80 -top-[600px] 2xl:left-[36rem] bg-[#B596DE] filter blur-3xl xl:blur-3xl 2xl:blur-[350px] opacity-20 2xl:opacity-40;
    }

    .bg-shade-left-long {
        @apply absolute rounded-full w-auto lg:w-96 xl:w-[800px] h-60 md:h-72 lg:h-96 xl:h-[1800px] bottom-[600px] md:-bottom-36 lg:-bottom-40 xl:-bottom-36 2xl:top-0 left-0 bg-[#B596DE] filter blur-3xl xl:blur-3xl 2xl:blur-[350px] opacity-20 2xl:opacity-40;
    }

    .bg-shade-right-long {
        @apply absolute rounded-full w-auto lg:w-96 xl:w-[800px] h-64 xl:h-[1800px] lg:h-96 md:h-80 -top-52 right-0 bg-[#B596DE] filter blur-3xl xl:blur-3xl 2xl:blur-[350px] opacity-20 2xl:opacity-40;
    }

    .bg-shade-center-long {
        @apply absolute rounded-full w-auto lg:w-96 xl:w-[1200px] h-64 xl:h-[1200px] lg:h-96 md:h-80 -top-96 left-0 xl:left-[23rem] bg-[#B596DE] filter blur-3xl xl:blur-3xl 2xl:blur-[350px] opacity-20 2xl:opacity-40;
    }

    .bg-shade-left-normal {
        @apply absolute rounded-full w-auto lg:w-96 xl:w-[800px] h-60 md:h-72 lg:h-96 xl:h-[1000px] bottom-[600px] md:-bottom-36 lg:-bottom-40 xl:-bottom-36 2xl:-top-52 bg-[#B596DE] filter blur-3xl xl:blur-3xl 2xl:blur-[350px] opacity-20 2xl:opacity-40;
    }

    .bg-shade-right-normal {
        @apply absolute rounded-full w-auto lg:w-96 xl:w-[800px] h-64 xl:h-[1000px] lg:h-96 md:h-80 -top-52 right-0 bg-[#B596DE] filter blur-3xl xl:blur-3xl 2xl:blur-[350px] opacity-20 2xl:opacity-40;
    }

    .bg-shade-left-blog {
        @apply absolute rounded-full md:w-72 lg:w-96 xl:w-[800px] h-60 md:h-72 lg:h-96 xl:h-[800px] bottom-[600px] md:-bottom-36 lg:-bottom-40 xl:-bottom-36 2xl:top-0 2xl:-left-[800px] bg-[#B596DE] filter blur-[350px] opacity-40;
    }

    /* Over Page Background Shadow */
}

@media screen and (min-width: 1500px) {
    .bg-shade-right {
        @apply absolute rounded-full w-auto lg:w-96 xl:w-[800px] h-64 xl:h-[800px] lg:h-96 md:h-80 -top-[600px] xl:-right-10 2xl:right-0 bg-[#B596DE] filter blur-3xl xl:blur-3xl 2xl:blur-[350px] opacity-20 2xl:opacity-40 overflow-hidden;
    }
}

/* scrollbar */
/* Styles for WebKit browsers (Chrome, Safari) */
.scrollbar::-webkit-scrollbar {
    width: 12px; /* Adjust the width as needed */
    height: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Set the color to gray */
    border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}

.horgintalGradientBorder {
    /* padding-left: 48px; */
    border-left: 1.5px solid transparent;
    border-image: linear-gradient(to left, #ffffff, #410ead, #ffffff) 1;
}

/* #calendly-container {
  height: 1000px !important;
  width: 680px !important;
  overflow: hidden !important;
} */
/* ._3efP_GeH5kyBAzqnLzL {
  width: 1000px,

} */

.calendlyStyle {
    width: 1000px !important;
    height: 580px !important;
}
.Rkt9F4BV7O3LQSywHdJy {
    overflow: hidden !important;
}
@media (max-width: 576px) {
    .calendlyStyle {
        width: 400px !important;
        height: 580px !important;
    }
}
@media (min-width: 576px) {
    .calendlyStyle {
        width: 400px !important;
        height: 580px !important;
    }
}
@media (min-width: 992px) {
    .calendlyStyle {
        width: 1000px !important;
        height: 580px !important;
    }
}

/* for react marker  */
.markdown-content {
    /* @apply text-[#7b3fe4] Your desired text color; */
    color: #7b3fe4;
}

.markdown-content a {
    /* @apply text-[#7b3fe4] Color for links, if needed; */
    color: #7b3fe4;
}

.custom-link {
    color: #7b3fe4; /* Your desired link color */
    text-decoration: underline; /* Optional: add underline to links */
}

/* for portfolio description// used in CommonCard component */
/* Custom CSS for multi-line truncation */
.text_hidden {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* For header source URL Not remove */
#top-menu-nav {
    display: none;
}
/* For header source URL Not remove */

/* gradient color for ServicesWork components */
.ServicesWork {
    @apply relative h-screen bg-white;
    @apply absolute top-0 right-0 h-1/3 w-1/2 bg-gradient-to-bl from-purple-300/50 to-transparent blur-3xl;
    @apply absolute bottom-0 left-0 h-1/3 w-1/2 bg-gradient-to-tr from-purple-300/50 to-transparent blur-3xl;
}

/* blockchain scrollbar bg black */
.custom-bg-scrollbar::-webkit-scrollbar {
    width: 1px;
}

.custom-bg-scrollbar::-webkit-scrollbar-track {
    background-color: transparent; /* Scrollbar track */
}

.custom-bg-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent; /* Scrollbar thumb */
}

.custom-bg-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
}

/* slick-list class for Our Costing in home page */
/* .slick-list {
    overflow: visible !important;
    border-radius: 10px !important;
} */

/* .slick-track {
    width: clamp(300px, 50vw, 615px) !important;
    overflow: visible !important;
    border-radius: 10px !important;
} */

/* before you leave input fileds */
.before-you-drop-shadow {
    box-shadow: 0px 0px 15px 0px rgba(65, 14, 173, 0.19);
    /* padding: 20px 30px;
    border-radius: 10px; */
}

.mobil_before_you {
    @apply rounded-lg py-3 px-3;
}
